<template>
  <v-app id="inspire">
    <v-app-bar app clipped-left flat color="primary" class="header">
      <v-container class="container_principal_pedido">
        <v-row>
          <v-col cols="9" class="logoConfigurarPedido" align="left">
            <a href="/">
              <img src="@/assets/logo_blanco.svg" class="logoBlanco" />
            </a>
          </v-col>
          <v-col
            cols="3"
            class="botonCambiarIdiomaWebCOnfigurarPedido"
            align="right"
          >
            <v-btn
              class="botonCambiarIdiomaPagina"
              v-if="this.$i18n.locale === 'gl'"
              @click="castellano()"
              ><span style="font-weight: lighter">ES</span> |
              <strong>GL</strong></v-btn
            >
            <v-btn
              class="botonCambiarIdiomaPagina"
              v-if="this.$i18n.locale === 'es'"
              @click="gallego()"
              ><strong>ES</strong> |
              <span style="font-weight: lighter">GL</span></v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
    <v-main>
      <v-container class="container_principal_pedido">
        <v-row>
          <v-col cols sm="12" style="text-align: justify">
            <v-row>
              <v-col cols>
                    <h1 style="text-align: center">Información Básica Datos</h1>
                <p style="padding-top: 25px">
                  <b>{{ $t("informacionDatos.parrafo1.textoNegrita") }}</b>
                  {{ $t("informacionDatos.parrafo1.texto") }}
                </p>
                <p>
                  <b>{{ $t("informacionDatos.parrafo2.textoNegrita") }}</b>
                  {{ $t("informacionDatos.parrafo2.texto") }}
                </p>
                <p>
                  <b>{{ $t("informacionDatos.parrafo3.textoNegrita") }} </b>
                  (+34) <a href="tel:+34982650065">982 65 00 65</a>
                </p>
                <p>
                  <b>{{ $t("informacionDatos.parrafo4.textoNegrita") }} </b
                  ><a href="mailto:info@norvoz.es">info@norvoz.es</a>
                </p>
                <p>
                  <b>{{ $t("informacionDatos.parrafo5.textoNegrita") }} </b
                  >{{ $t("informacionDatos.parrafo5.texto") }}
                </p>
                <p>
                  <b>{{ $t("informacionDatos.parrafo6.textoNegrita") }} </b
                  >{{ $t("informacionDatos.parrafo6.texto") }}
                </p>
                <p>
                  <b>{{ $t("informacionDatos.parrafo7.textoNegrita") }} </b
                  >{{ $t("informacionDatos.parrafo7.texto") }}
                </p>
                <p>
                  <b>{{ $t("informacionDatos.parrafo8.textoNegrita") }} </b
                  >{{ $t("informacionDatos.parrafo8.texto") }}
                </p>
                <p>
                  <b>{{ $t("informacionDatos.parrafo9.textoNegrita") }} </b
                  >{{ $t("informacionDatos.parrafo9.texto") }}
                </p>
                  <p>
                  <b>{{ $t("informacionDatos.parrafo10.textoNegrita") }} </b
                  >{{ $t("informacionDatos.parrafo10.textoParte1") }} <router-link to="aviso-legal">
             Aviso Legal</router-link
            > {{ $t("informacionDatos.parrafo10.textoParte2") }}
                </p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <!-- Footer -->
    <FooterView />
    <!-- Footer -->
  </v-app>
</template>

<script>
export default {
    name: 'App',
    components: {
        FooterView: () => import('./footer.vue')
    },
    data () {
        return {
            e6: 1,
            datosPortabilidadesValidados: true,
            datosClienteValidados: false
        }
    },
    methods: {
        castellano () {
            this.$i18n.locale = 'es'
        },
        gallego () {
            this.$i18n.locale = 'gl'
        },
        volverPortada () {
            this.$store.dispatch('setcarritoPedidosLineasMovil', '')
            this.$store.dispatch('setLineasMovil', [])

            this.$router.push('/')
        }
    },

    created () {}
}
</script>
<style>
</style>
